import "./src/css/site.css";
import "@fontsource/poiret-one";
import "@fontsource/archivo";
import "@fontsource/raleway/700.css"
import React from "react";

// import BlogDataProvider from "./src/data/BlogDataContext";
import SimpleReactLightbox from "simple-react-lightbox";

export const wrapRootElement = ({ element }) => {
  return (
    // <BlogDataProvider>
    // <>
    //   <link rel="preconnect" href="https://fonts.gstatic.com" />
    //   <link
    //     href="https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap"
    //     rel="stylesheet"
    //   />
    //   <link
    //     href="https://fonts.googleapis.com/css2?family=Archivo&display=swap"
    //     rel="stylesheet"
    //   />
    //   <link
    //     href="https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap"
    //     rel="stylesheet"
    //   />
    <SimpleReactLightbox>{element}</SimpleReactLightbox>
    // </>
    // </BlogDataProvider>
  );
};
